import { toast } from "react-toastify";
import { cipher, decipher } from './security';


export class Common {
    saltKey: string = 'garmenzo-key-salt-mumbai-danbury';
    showSuccessMessage = (message: string) => toast.success(message, { autoClose: 3000, theme: "colored", icon: false });
    showErrorMessage = (message: string) => toast.error(message, { autoClose: 3000, theme: "colored", icon: false });

    /**
     * Formats the size
     */
    formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    encrypt = (text: string) => {
       const myCipher = cipher(this.saltKey)
        return myCipher(text);
    }

    decrypt = (text: string) => {
        const myDecipher = decipher(this.saltKey)
         return myDecipher(text);
     }
    // To create a cipher
  

// //Then cipher any text:
// console.log(myCipher('the secret string'))

// //To decipher, you need to create a decipher and use it:
// const myDecipher = decipher('mySecretSalt')
// console.log(myDecipher("7c606d287b6d6b7a6d7c287b7c7a61666f"))






}