import React from 'react';
import { PermissionType } from 'types/PermissionType';

type MenuContextType = {
    isAllowedTo: (page: any) => boolean;
}

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider.
// The permission will not be granted unless a provider says otherwise
const defaultBehaviour: MenuContextType = {
    isAllowedTo: () => false
}

// Create the context
const MenuContext = React.createContext<MenuContextType>( defaultBehaviour);

export default MenuContext;