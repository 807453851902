import React, { useContext } from 'react';
import MenuContext from "./MenuContext";
import { Common } from 'helpers/common';

type Props = {
    children: React.ReactNode, // Add the 'children' property to the 'Props' type
    menus: any[],
}

// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const MenuProvider: React.FunctionComponent<Props> = ({menus,children }) => {

    //const { menus } = useContext(AppContext);

    // Creates a method that returns whether the requested permission is available in the list of permissions
    // passed as parameter
    //const isAllowedTo = (page: PermissionType,permission: PermissionType) => permissions.some(p=>p.resource===page);

    const isAllowedTo = (page: any) => {
        const common = new Common();
        const role = localStorage.getItem("IDKS@ROLE") || "";
        const isAdmin = common.decrypt(role) === "admin";
        
       // console.log(menus,page)
        
        return isAdmin || ( menus.some(p => p.menuName === page));
    }
    // This component will render its children wrapped around a PermissionContext's provider whose
    // value is set to the method defined above
    return <MenuContext.Provider value={{ isAllowedTo }}>{children}</MenuContext.Provider>;
};

export default MenuProvider;