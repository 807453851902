import React, { useEffect, useState } from 'react'
import Modal from 'Common/Components/Modal';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import { Common } from 'helpers/common';
import Select from "react-select";
import {
    getSalesRepresentatives,
    addCouponList as onAddCouponList,
    updateCouponList as onUpdateCouponList,
} from '../../../helpers/backend_helper';
import { Loader2 } from 'lucide-react';
import "flatpickr/dist/flatpickr.css";

type Props = { onHide: () => void; data: any, onSave: (result: any) => void }

const AddCoupon: React.FC<Props> = ({ onHide, data, onSave }) => {

    const [couponData, setCouponData] = useState<any>(data);
    const [users, setUsers] = useState<any[]>();
    const [selectedUser, setSelectedUser] = useState<any>();
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [editorData, setEditorData] = useState('<br/><br/>');
    const commonHelper = new Common();

    useEffect(() => {
        if (couponData?.id) {
            console.log('couponData',couponData);
            setIsEdit(couponData?.id);
            setEditorData(couponData.description);
        }
        loadRepresentatives();
    }, [])


const loadRepresentatives=async()=>{
        try {
          const response = await getSalesRepresentatives();
          const data = response.data.map((item: any) => ({
            value: item.id,
            label: item.fullName,
          }));
          const options = [{ value: "", label: "Select" }, ...data];
          setUsers(options);
        } catch (error) {
          console.error("Error loading roles", error);
        }
      }
    

    // validation
    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            // couponType: (couponData && couponData.couponType) || '',
            couponCode: (couponData && couponData.couponCode) || '',
            couponValue: (couponData && couponData.couponValue) || 0,
            description: (couponData && couponData.description) || '',
            issuedToUserId: (couponData && couponData.issuedToUserId) || '',
            validFromDate: (couponData && couponData.validFromDate) || '',
            validToDate: (couponData && couponData.validToDate) || '',
            forMinOrderValue: (couponData && couponData.forMinOrderValue) || 0,
            forNumberOfTrans: (couponData && couponData.forNumberOfTrans) || 0,
            maxRedemptions: (couponData && couponData.maxRedemptions) || 0,
            
            isActive: (couponData && couponData.isActive) || true
        },
        validationSchema: Yup.object({
            // couponType: Yup.string().required("Please Enter Coupon Type !"),
            couponCode: Yup.string().required("Please Enter Coupon Code !"),
            couponValue: Yup.number().required("Please Enter Coupon Value !"),
            description: Yup.string().required("Please Enter Description !"),
            // issuedTo: Yup.string().required("Please Enter Issued To !"),
            validFromDate: Yup.date().required("Please Enter Valid From Date !"),
            validToDate: Yup.date().required("Please Enter Valid To Date !"),
            forMinOrderValue: Yup.number().required("Please Enter Minimum Order Amount !"),
            forNumberOfTrans: Yup.number().required("Please Enter Number Of Trans To Redeem!"),
            maxRedemptions: Yup.number().required("Please Enter Number Of Trans To Redeem!"),
            isActive: Yup.bool().required("Please choose yes or no!")
        }),

        onSubmit: async (values: any) => {
            setIsSaving(true);

            if (isEdit && couponData) {
                try {
                    // update data
                    const updateData = {
                        ...values,
                        id: couponData.id,
                        isActive:true
                    };

                    var result = await onUpdateCouponList(updateData);
                    commonHelper.showSuccessMessage('Coupon has been successfully updated.');
                    onSave(result.data.rows[0]);

                } catch (error) {
                    console.log('API-Error', error);
                    commonHelper.showErrorMessage(error as string);
                    setIsSaving(false);
                }

                setIsEdit(false);
            } else {
                // save new user

                try {
                    const newData = { ...values,
                        isActive:true
                     };

                     console.log('Coupon Data', newData);
                    var result = await onAddCouponList(newData);
                    commonHelper.showSuccessMessage('Coupon has been successfully saved.');
                    onSave(result.data.rows[0]);
                } catch (error) {
                    console.log('API-Error', error);
                    commonHelper.showErrorMessage(error as string);
                    setIsSaving(false);
                }
            }
            setIsSaving(false);
        },

    });

    const handleStartDateChange = (selectedDates: any, dateStr: any, instance: any) => {
        console.log(dateStr)
        validation.setFieldValue('validFromDate', dateStr)
    }

    const handleEndDateChange = (selectedDates: any, dateStr: any, instance: any) => {
        validation.setFieldValue('validToDate', dateStr)
    }


    const handleUserChange=(user:any)=>{
        validation.setFieldValue('issuedToUserId', user.value)
console.log(user);
    }
    return (
        <React.Fragment>
            <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                <Modal.Title className="text-16">{!!isEdit ? "Edit Coupon" : "Add Coupon"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                <form action="#!" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>



                            <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                                {/* <div className="xl:col-span-4">
                                    <label htmlFor="couponType" className="inline-block mb-2 text-base font-medium">Coupon Type</label>
                                    <input type="couponType" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        id="couponType"
                                        name="couponType"
                                        placeholder="coupon type"

                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.couponType || ""} />
                                    {validation.touched.couponType && validation.errors.couponType ? (
                                        <div id="couponType-error" className="mt-1 text-sm text-red-500">{validation.errors.couponType}</div>
                                    ) : null}
                                </div> */}

                                <div className="xl:col-span-6">
                                    <label htmlFor="couponCode" className="inline-block mb-2 text-base font-medium">Coupon Code</label>
                                    <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        id="couponCode"
                                        name="couponCode"
                                        placeholder="coupon code"

                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.couponCode || ""} />
                                    {validation.touched.couponCode && validation.errors.couponCode ? (
                                        <div id="couponCode-error" className="mt-1 text-sm text-red-500">{validation.errors.couponCode}</div>
                                    ) : null}
                                </div>

                                <div className="xl:col-span-6">
                                    <label htmlFor="couponValue" className="inline-block mb-2 text-base font-medium">Coupon Value</label>
                                    <input type="number" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        id="couponValue"
                                        name="couponValue"
                                        placeholder="coupon value"

                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.couponValue || ""} />
                                    {validation.touched.couponValue && validation.errors.couponValue ? (
                                        <div id="couponValue-error" className="mt-1 text-sm text-red-500">{validation.errors.couponValue}</div>
                                    ) : null}
                                </div>

                                <div className="lg:col-span-2 xl:col-span-12">
                                    <label htmlFor="description" className="inline-block mb-2 text-base font-medium">Description</label>
                                    <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        id="description"
                                        name="description"
                                        placeholder="description"

                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.description || ""} />
                                    {validation.touched.description && validation.errors.description ? (
                                        <div id="description-error" className="mt-1 text-sm text-red-500">{validation.errors.description}</div>
                                    ) : null}
                                </div>

                                <div className="lg:col-span-2 xl:col-span-6">
                                    <label htmlFor="validFromDate" className="inline-block mb-2 text-base font-medium">Valid From</label>
                                    <Flatpickr
                                id="validFromDate"
                                options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "m/d/Y",
                                 }}
                                onChange={handleStartDateChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.validFromDate || ""}
                                placeholder="Select Date"
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            />

                                  
                                    {/* <input
                                        id="validFromDate"
                                        type="date"
                                    
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.validFromDate || ""}
                                        placeholder="valid from date"
                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    /> */}
                                    {validation.touched.validFromDate && validation.errors.validFromDate ? (
                                        <div id="validFromDate-error" className="mt-1 text-sm text-red-500">{validation.errors.validFromDate}</div>
                                    ) : null}
                                </div>

                                <div className="lg:col-span-2 xl:col-span-6">
                                    <label htmlFor="validToDate" className="inline-block mb-2 text-base font-medium">Valid From</label>
                                    {/* <input
                                    type="date"
                                        id="validToDate"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.validToDate || ""}
                                        placeholder="valid from date"
                                        className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    /> */}
                                     <Flatpickr
                                id="validToDate"
                                options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "m/d/Y",
                                 }}
                                onChange={handleEndDateChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.validToDate || ""}
                                placeholder="Select Date"
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            />
                                    {validation.touched.validToDate && validation.errors.validToDate ? (
                                        <div id="validToDate-error" className="mt-1 text-sm text-red-500">{validation.errors.validToDate}</div>
                                    ) : null}
                                </div>


                                <div className="lg:col-span-2 xl:col-span-12">
                                    <label htmlFor="issuedToUserId" className="inline-block mb-2 text-base font-medium">Issued To</label>
                                    <Select
                                        className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        options={users}
                                        isSearchable={true}
                                        value={selectedUser}
                                        defaultValue={selectedUser}
                                        onChange={(event: any) => handleUserChange(event)}
                                        id="choices-single-default"
                                        />
                                </div>

                          
                                <div className="lg:col-span-2 xl:col-span-4">
                                    <label htmlFor="forMinOrderValue" className="inline-block mb-2 text-base font-medium">Minimum Order</label>
                                    <input type="number" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        id="forMinOrderValue"
                                        name="forMinOrderValue"
                                        placeholder="for Min Order 2000"

                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.forMinOrderValue || ""} />
                                    {validation.touched.forMinOrderValue && validation.errors.forMinOrderValue ? (
                                        <div id="forMinOrderValue-error" className="mt-1 text-sm text-red-500">{validation.errors.forMinOrderValue}</div>
                                    ) : null}
                                </div>

                                <div className="lg:col-span-2 xl:col-span-4">
                                    <label htmlFor="forNumberOfTrans" className="inline-block mb-2 text-base font-medium">Number Of Trans To Redeem</label>
                                    <input type="number" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        id="forNumberOfTrans"
                                        name="forNumberOfTrans"
                                        placeholder="for Number Of Transaction to Redeem"

                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.forNumberOfTrans || ""} />
                                    {validation.touched.forNumberOfTrans && validation.errors.forNumberOfTrans ? (
                                        <div id="forNumberOfTrans-error" className="mt-1 text-sm text-red-500">{validation.errors.forNumberOfTrans}</div>
                                    ) : null}
                                </div>

                                <div className="lg:col-span-2 xl:col-span-4">
                                    <label htmlFor="maxRedemptions" className="inline-block mb-2 text-base font-medium">Total coupons to be issued</label>
                                    <input type="number" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        id="maxRedemptions"
                                        name="maxRedemptions"
                                        placeholder="Total coupons to be issued"

                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.maxRedemptions || ""} />
                                    {validation.touched.maxRedemptions && validation.errors.maxRedemptions ? (
                                        <div id="maxRedemptions-error" className="mt-1 text-sm text-red-500">{validation.errors.maxRedemptions}</div>
                                    ) : null}
                                </div>


                            </div>

                            <div className="flex justify-end gap-2 mt-4">
                                <button type="reset" data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" onClick={onHide}>Cancel</button>

                                {!isSaving ?
                                    <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        {!!isEdit ? "Update Coupon" : "Add Coupon"}
                                    </button>
                                    : <button type="button" disabled className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                                        Saving...
                                    </button>
                                }

                            </div>

                </form>
            </Modal.Body>
        </React.Fragment>
    )
}

export default AddCoupon;