import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";

type Props = {
    children?: ReactNode;
}

type IAppContext = {
    permissions: any[];
    setPermissions: (permissions: any[]) => void;

    menus: any[];
    setMenus: (menus: any[]) => void;

    userName: string;
    setUserName: (userName: string) => void;
}

const initialValue = {
    permissions: [],
    setPermissions: (permissions: any[]) => { },

    menus: [],
    setMenus: (menus: any[]) => { },

    userName: '',
    setUserName: (userName: string) => {}
}

export const AppContext = createContext<IAppContext>(initialValue)

export const AppContextProvider = ({ children }: Props) => {
    const [permissions, setPermissionsData] = useState<any[]>([]);
    const [menus, setMenuData] = useState<any[]>([]);
    const [userName, setUserNameData] = useState<any>('');

    const setPermissions = (appPermissions: any[]) => {
        console.log('App Context permissions', appPermissions);
        setPermissionsData(appPermissions); // Update the permissions state with the new value
    };


    const setMenus = (appMenus: any[]) => {
        console.log('App Context menus', appMenus);
        setMenuData(appMenus); // Update the menus state with the new value
    };

    const setUserName = (userName: any) => {
        console.log('App Context user name', userName);
        setUserNameData(userName); // Update the menus state with the new value
    };

    const allObj = {
        permissions,
        setPermissions,

        menus,
        setMenus,

        setUserName,
        userName
    };

    useEffect(() => {
        const appPermissions = localStorage.getItem("app-permissions");
        const appMenus = localStorage.getItem("app-menus");
        const appUser = localStorage.getItem("user-name") || 'No User';
        
        setPermissionsData(appPermissions !== null? JSON.parse(appPermissions):[]);
        setMenuData(appMenus !== null? JSON.parse(appMenus):[]);
        setUserNameData(appUser);

        // if (appPermissions !== null) {
        // } else {
        //     setPermissionsData([]);
        // }
    }, [])


    return (
        <AppContext.Provider value={allObj}>
            {children}
        </AppContext.Provider>
    );
}

