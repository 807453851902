import React, { useEffect, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import WelcomeWidget from './WelcomeWidget';
import OrderStatistics from './OrderStatistics';
import Widgets from './Widgets';
import SalesRevenue from './SalesRevenue';
import TrafficResources from './TrafficResources';
import ProductsOrders from './ProductsOrders';
import CustomerService from './CustomerService';
import SalesMonth from './SalesMonth';
import TopSellingProducts from './TopSellingProducts';
import Audience from './Audience';
import { useNavigate } from 'react-router-dom';
import { getMonthlyOrderCounts, getOrders, getTotalOrderCounts } from 'helpers/backend_helper';
import { TotalOrderCount } from 'types/OrderType';
import { OrdersOverviewChart } from 'pages/Ecommerce/charts';
import moment from 'moment';

const Ecommerce = () => {

    const navigate = useNavigate();
    const [totalOrderCounts, setTotalOrderCounts] = useState<TotalOrderCount>({} as TotalOrderCount);
    const [monthlyOrderCounts, setMonthlyOrderCounts] = useState<number[]>([]);
    const [totalOrders, setTotalOrders] = useState<any>([]);

    useEffect(() => navigate("/dashboard"), [navigate]);

    useEffect(() => {
        onGetDashboardItems();
    }, []);

    const onGetDashboardItems = async () => {

        try {
            const response = await getOrders();
            const orderHistory = response.data.sort((a: any, b: any) => (a.updatedAt > b.updatedAt ? -1 : 1));
            setTotalOrders(orderHistory);
            console.log(orderHistory);
        } catch (error) {

        }


        // load total order count
       try{
        const responseTotalOrders = await getTotalOrderCounts();
        console.log(responseTotalOrders);
        var orderCounts = {} as TotalOrderCount;

        orderCounts.newOrders = responseTotalOrders.data.find(p => p.status === 'Order Placed')?.ordercount;
        let deliveredOrders = responseTotalOrders.data.find(p => p.status === 'Order Delivered')?.ordercount;
        let completedOrders = responseTotalOrders.data.find(p => p.status === 'Order Completed')?.ordercount;
        orderCounts.deliveredOrders = parseInt(deliveredOrders || 0) + parseInt(completedOrders || 0);
        orderCounts.pendingOrders = responseTotalOrders.data.find(p => p.status === 'Order Processing')?.ordercount;
        orderCounts.shippedOrders = responseTotalOrders.data.find(p => p.status === 'Order Shipped')?.ordercount;

        const sum = responseTotalOrders.data.reduce((accumulator, object) => {
            return parseInt(accumulator) + parseInt(object.ordercount);
        }, 0);

        orderCounts.totalOrders = sum;
        setTotalOrderCounts(orderCounts);
    } catch (error) {

    }


        // monthly order counts
        // load total order count

        try{
        const responseMonthlyOrdes = await getMonthlyOrderCounts();

        let monthsCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        console.log(responseMonthlyOrdes)

        responseMonthlyOrdes.data.forEach((element: any) => {
            var momentDate = moment(element.month)

            const month = momentDate.month();
            monthsCount[month - 1] = parseInt(element.orderCount);
        });

        setMonthlyOrderCounts(monthsCount);
        console.log(monthsCount);

    } catch (error) {

    }

    }

    return (
        <React.Fragment>
            <BreadCrumb title='Ecommerce' pageTitle='Dashboards' />
            <div className="grid grid-cols-12 gap-x-5">
                <WelcomeWidget />
                <OrderStatistics />
                <Widgets data={totalOrderCounts} />
                {/* <SalesRevenue /> */}
                <div className="order-last md:col-span-2 2xl:col-span-8 2xl:row-span-3 card 2xl:order-none">
                    <div className="card-body">
                        <h6 className="mb-4 text-gray-800 text-15 dark:text-zink-50">Orders Overview</h6>
                        <OrdersOverviewChart id="ordersOverview" data={monthlyOrderCounts} />
                    </div>
                </div>

                {/* <TrafficResources /> */}
                <ProductsOrders orders={totalOrders} />
                <CustomerService />
                <SalesMonth />
                <TopSellingProducts />
                <Audience />
            </div>
        </React.Fragment>
    );
};

export default Ecommerce;
