import React, {useContext} from 'react';
import PermissionContext from "./PermissionContext";
import { PermissionType } from 'types/PermissionType';

type Props = {
    page: PermissionType;
    to: PermissionType;
    children: React.ReactNode // Add the 'children' property to the 'Props' type

};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<Props> = ({page, to, children}) => {

    // We "connect" to the provider thanks to the PermissionContext
    const {isAllowedTo} = useContext(PermissionContext);

    // If the user has that permission, render the children
    if(isAllowedTo(page,to)){
        return <>{children}</>;
    }

    // Otherwise, do not render anything
    return null;
};

export default Restricted;