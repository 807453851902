import { FileText, MonitorDot, Settings,  ShoppingBag, UserRound } from "lucide-react";

const menuData: any = [
    // {
    //     label: 'menu',
    //     isTitle: true,
    // },
    {
        id: "dashboard",
        label: 'Dashboards',
        link: "/dashboard",
        icon: <MonitorDot />,
    },
    {
        id: "catalog",
        label: 'Catalog',
        link: "/#",
        icon: <ShoppingBag />,
        subItems: [
            {
                id: 'categories',
                label: 'Categories',
                parentId: 'catalog',
                link: '/category-list',
            },
            {
                id: 'brands',
                label: 'Brands',
                link: '/brands-list',
                parentId: 'catalog',
            },
            {
                id: 'products',
                label: 'Products',
                link: '/product-list',
                parentId: 'catalog',
            },
            {
                id: 'attributes',
                label: 'Product Attributes',
                link: '/attribute-list',
                parentId: 'catalog'
            },
            {
                id: 'attributevalue',
                label: 'Product Atttribute Values',
                link: '/attribute-value-list',
                parentId: 'catalog'
            },
            {
                id: 'productsizes',
                label: 'Product Sizes',
                link: '/product-size-list',
                parentId: 'catalog'
            },           
            {
                id: 'productcolors',
                label: 'Product Color',
                link: '/product-color-list',
                parentId: 'catalog'
            },
            
            
        ]
    },
   
    {
        id: 'contentmanagement',
        label: 'Content Management',
        icon: <FileText />,
        parentId: 2,
        subItems: [
            {
                id: 'announcements',
                label: 'Announcements',
                link: '/announcement-list',
                parentId: 'contentmanagement'
            },
            {
                id: 'faqs',
                label: 'FAQ',
                link: '/faq-list',
                parentId: 'contentmanagement'
            },
            {
                id: 'cms',
                label: 'CMS',
                link: '/cms-list',
                parentId: 'contentmanagement'
            }
            ,
            {
                id: 'banners',
                label: 'Banners',
                link: '/banner-list',
                parentId: 'contentmanagement'
            }
            ,
            {
                id: 'coupons',
                label: 'Coupons',
                link: '/coupon-list',
                parentId: 'contentmanagement'
            },
            
        ]
    },
    {
        id: 'configuration',
        label: 'Configuration',
        icon: <Settings />,
        parentId: 2,
        subItems: [
            {
                id: 'common-settings',
                label: 'Common Settings',
                link: '/common-settings',
                
                parentId: 'configuration'
            },{
                id: 'email-settings',
                label: 'Email Settings',
                link: '/email-settings',
                parentId: 'configuration'
            },
                // {
                //     id: 'season',
                //     label: 'Manage Season',
                //     link: '/season-list',
                //     parentId: 'configuration'
                // }
                // ,
            {
                id: 'districts',
                label: 'Districts',
                link: '/district-list',
                parentId: 'configuration'
            },
            {
                id: 'cities',
                label: 'Cities',
                link: '/city-list', 
                parentId: 'configuration'
            }, 
            {
                id: 'users',
                label: 'Users',
                link: '/userlist',
                parentId: 'configuration'
            },
            
            // {
            //     id: 'user-roles',
            //     label: 'User Roles',
            //     link: '/user-roles',
            //     parentId: 'configuration'
            // },
            {
                id: 'roles',
                label: 'Roles',
                link: '/roles',
                parentId: 'configuration'
            },
            {
                id: 'resources',
                label: 'Resources',
                link: '/resources',
                parentId: 'configuration'
            }, 

            {
                id: 'permissions',
                label: 'Permissions',
                link: '/permissions',
                parentId: 'configuration'
            }
        ]
    },
    {
        id: 'order-management',
        label: 'Order Management',
        icon: <FileText />,
        parentId: 2,
        subItems: [
            {
                id: 'orders',
                label: 'Orders',
                link: '/orders',
                parentId: 'order-management'
            }
            ,
            {
                id: 'carts',
                label: 'Carts',
                link: '/carts',
                parentId: 'order-management'
            },
            {
                id: 'wishlists',
                label: 'Wishlist',
                link: '/wishlist',
                parentId: 'order-management'
            },
            {
                id: 'returnrequest',
                label: 'Return Requests',
                link: '/return-request',
                parentId: 'order-management'
            }
        ]
    },
    {
        id: 'customer-management',
        label: 'Customer Management',
        icon: <UserRound />,
        parentId: 2,
        subItems: [
            {
                id: 'customers',
                label: 'Customers',
                link: '/customer-list',
                parentId: 'customer-management'
            },
            {
                id: 'track-activity',
                label: 'Track Activity',
                link: '/track-activity',
                parentId: 'customer-management'
            }
        ]
    },

    {
        id: 'report-management',
        label: 'Reports',
        icon: <UserRound />,
        parentId: 2,
        subItems: [
            {
                id: 'sales-summary',
                label: 'Sales Summary',
                link: '/sales-summary',
                parentId: 'report-management'
            },
            {
                id: 'low-inventory',
                label: 'Low Inventory',
                link: '/low-inventory',
                parentId: 'report-management'
            },
            {
                id: 'best-seller',
                label: 'Best Seller',
                link: '/best-seller',
                parentId: 'report-management'
            }
            ,
            {
                id: 'product-never-sold',
                label: 'Product Never Sold',
                link: '/product-never-sold',
                parentId: 'report-management'
            },
            {
                id: 'customer-order',
                label: 'Customer Order',
                link: '/customer-order',
                parentId: 'report-management'
            }
        ]
    },
  
];



export const  UserPermissions:any=[
    {
      resource: "brands"
    },
    {
      resource: "productimages"
    },
    {
      resource: "faqs"
    },
    {
      resource: "users"
    },
    {
      resource: "seasons"
    },
    {
      resource: "categories"
    },
    {
      resource: "businessaddresses"
    },
    {
      resource: "wishlists"
    },
    {
      resource: "orderheaders"
    },
    {
      resource: "ordertrackings"
    },
    {
      resource: "shippingaddresses"
    },
    {
      resource: "billingaddresses"
    },
    {
      resource: "coupons"
    },
    {
      resource: "orders"
    },
    {
      resource: "businesses"
    },
    {
      resource: "banners"
    },
    {
      resource: "attributevalues"
    },
    {
      resource: "attributes"
    },
    {
      resource: "products"
    },
    {
      resource: "cms"
    },
    {
      resource: "productcolors"
    },
    {
      resource: "carts"
    },
    {
      resource: "productpackagings"
    },
    {
      resource: "orderlines"
    },
    {
      resource: "productsizes"
    },
    {
      resource: "productvariants"
    },
    {
      resource: "announcements"
    },
    {
      resource: "businesscoupons"
    }
  ]

export { menuData };