import axios from "axios";
//const BASE_URL = 'http://194.238.22.154:4000/api/v1';//'https://garments-wdop.onrender.com/api/v1';//
//const BASE_URL = 'https://garments-wdop.onrender.com/api/v1';
const BASE_URL = 'https://api.garmenzo.com/api/v1';

// const useAxios = () => {

const axiosPrivate = axios.create({
     baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
});

//const navigate = useNavigate();

axiosPrivate.interceptors.request.use(
    config => {
        const token = localStorage.getItem("token") || '';

        if (!config.headers['Authorization']) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    }, (error) => Promise.reject(error)
);

axiosPrivate.interceptors.response.use(
    (response) => response?.data || response,
    async (error) => {
        const originalRequest = error.config;

        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        if ((error.response.status === 401) && !originalRequest._retry) {
            originalRequest._retry = true;
            window.location.href = '/login';

           // const navigate = useNavigate();
            // try {
            //     const refreshToken = localStorage.getItem('refreshToken');
            //     let data = { token: refreshToken }

            //     const response = await axios.post(BASE_URL + '/refreshtokens', data);

            //     console.log('useResponse', response.data.data);
            //     let authToken = response.data.data.authToken;
            //     authToken = authToken.replace('Bearer ', '');

            //     localStorage.setItem('token', response?.data?.data?.authToken.replace('Bearer ', ''));

            //     // Retry the original request with the new token
            //     originalRequest.headers.Authorization = `Bearer ${authToken}`;
            //     return axios(originalRequest);
            // } catch (error) {
               
            //     // Handle refresh token error or redirect to login
            //     // const navigate = useNavigate();
            //     //navigate('/login');
            //     window.location.href = '/login';
            //     console.log('refresh',error)

            // }
        }
        if (error.response.status === 500) {
            //return Promise.reject('Some error occured while processing the request. Please try again.');
            return Promise.reject(error);
            //return error.response?.data || error.response;
        }
  
        return Promise.reject(error);
    }
);
//  return { axiosPrivate };
// };

//export default useAxios;

export default axiosPrivate;